import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';
import PortableText from 'react-portable-text';

import {
	Button,
	Typography,
	Grid,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import { Link } from 'gatsby';

import FormModalContext from '../../context/FormModalContext';
import { GatsbyImage } from 'gatsby-plugin-image';

const useStyles = makeStyles((theme) => ({
	bgImg: {
		padding: '1.5rem 0',
		// [theme.breakpoints.down('lg')]: {
		// 	padding: '2rem 0',
		// },
		[theme.breakpoints.down('lg')]: {
			padding: '1.5rem .625rem',
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: '500px',
		},
	},
	button: {
		background: '#2A7ABC',
		color: 'white',
		padding: '16px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			background: '#2A7ABC',
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			margin: '2rem 0 .5rem',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '2rem 0 1rem',
		},
	},
	content: {
		color: '#FFF',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
			padding: '0 25px',
		},
	},
	calloutHeader: {
    display: 'inline',
		fontSize: '2.0625rem',
		lineHeight: 1.1,
		color: '#002D5C',
    marginTop: '1rem',
		marginBottom: '.5rem',
		fontWeight: 700,
		[theme.breakpoints.down('md')]: {
			textAlign: 'start',
			padding: '0 0 0 15px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '2rem',
		},
	},
	calloutIntro: {
		color: '#fff',
		textTransform: 'uppercase',
		fontSize: '1.5rem',
		fontWeight: 700,
    margin: '10px 0',
		[theme.breakpoints.down('md')]: {
			textAlign: 'start',
			padding: '15px 0 0 15px',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.3rem',
			lineHeight: '2rem',
		},
	},
	calloutSubHeader: {
		color: '#002D5C',
		lineHeight: '1.5rem',
		fontSize: '1.125rem',
		fontWeight: 400,
		[theme.breakpoints.down('md')]: {
			textAlign: 'left',
			padding: '0 15px',
		},
	},
}));

export const LogoLeftCtaCard = ({
	background,
	header,
	subheader,
	ctaText,
	ctaLink,
	internalLink,
	ctaLogo,
}) => {
	const classes = useStyles();

	const { handleModalClick } = useContext(FormModalContext);
console.log(header);
	const md = useMediaQuery('(max-width: 960px)');
	return (
		<Grid container justifyContent='center'>
			<Grid
				container
				item
				xs={12}
				md={8}
				lg={12}
				xl={12}
				style={{
					borderRadius: '20px',
					boxShadow: '0 10px 40px 0px rgba(0,0,0,0.2)',
					overflow: 'hidden',
					background: 'white',
					// backgroundSize: 'cover',
					position: 'relative',
					zIndex: 50,
				}}
				className={classes.bgImg}>
				<Grid
					container
					direction='row'
					justifyContent='space-evenly'
					alignItems='center'>
          {ctaLogo && (
					<Grid
						container
						item
						xs={12}
						md={10}
						lg={3}
						justifyContent='space-evenly'
						alignItems='flex-start'>
						<GatsbyImage image={ctaLogo?.asset.gatsbyImageData} />
					</Grid>
          )}
					<Grid
						item
						container
						direction='column'
						xs={12}
						md={10}
						lg={ctaLogo ? 6 : 8}
						justifyContent='space-evenly'
						alignItems='flex-start'>
						{header.length ? (
							<div style={{ lineHeight: '1rem' }}>
								<PortableText
									content={header}
									serializers={{
										h3: ({ children }) => (
											<Typography
												variant='h3'
												className={classes.calloutHeader}
                        style={{ display: 'block' }}>
												{children}
											</Typography>
										),
										h4: ({ children }) => (
											<Typography variant='h4' className={classes.calloutIntro}>
												{children}
											</Typography>
										),
                    code: ({ children }) => (
                      <span style={{
                        backgroundColor: '#055291',
                        padding: '5px 10px',
                        borderRadius: '8px',
                      }}>
                        {children}
                      </span>
                    ),
                    strong: ({ children }) => (
											<Typography
												variant='h3'
												className={classes.calloutHeader}
                        style={{ color: '#055291', paddingLeft: 0, }}>
												{children}
											</Typography>
                    ),
									}}
								/>
							</div>
						) : (
							<Typography variant='h2' className={classes.calloutHeader}>
								{header}
							</Typography>
						)}

						<Typography variant='body1' className={classes.calloutSubHeader}>
							{subheader}
						</Typography>
					</Grid>
					<Grid
						item
						container
						alignItems='center'
						justifyContent='center'
						xs={12}
						lg={3}>
						{ctaLink ? (
							internalLink ? (
								<Link to={`/${ctaLink}`} style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={classes.button}>
										{ctaText}
									</Button>
								</Link>
							) : (
								<a
									href={ctaLink}
									target='_blank' aria-label="Opens in a new tab"
									style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={classes.button}>
										{ctaText}
									</Button>
								</a>
							)
						) : (
							<Button
								variant='contained'
								size='large'
								className={classes.button}
								onClick={handleModalClick}>
								{ctaText}
							</Button>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
